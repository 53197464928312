.ProfileImg{
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    display: inline-block;
    position: absolute;
    top: -70px;
    left: -55px;
    width: 140px;
    height: 140px;
    overflow: hidden;
    border-radius: 50%;
}
.ProfileName{
    font-family: 'Bebas Neue', cursive;
    font-size: 48px;
    line-height: 64px;
    color: #18191F;
}
.ProfileAddress {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 0px;
    color: #3C3D41;
}
.ProfileMemberSince {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #6D6E71;
}
.profileTabs.nav-tabs {
    display: flex !important;
    justify-content: center !important;
    border: none;
}
.profileTabs .nav-link {
    border:none;
    margin-left:2px;
    margin-right:2px;
    font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
text-decoration-line: underline;
color: #000000;
margin-left:2px;
margin-right:2px;
}
.profileTabs .nav-link:hover, .profileTabs .nav-link:focus {
border: none;
margin-left:2px;
margin-right:2px;
}
.profileTabs .nav-link.active {
    background: #000000;
border-radius: 24px;
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
text-decoration: none;
color: #FFFFFF;
}
.CollectionCard {
    width: 340px !important;
    filter: drop-shadow(0px 4px 31px rgba(0, 0, 0, 0.1));
    border: none;
    border-radius: 12px;

}
.CollectionCard .Title {
    font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */

font-feature-settings: 'salt' on, 'liga' off;

color: #B74AC9;
}
.CollectionCard .AvatarImage {
    position: absolute;
    top: -20px;
    right: -25px;
}
#ProfileTabs-tab-settings.nav-item.nav-link.active {
    background-color: white !important;

}
#ProfileTabs-tab-settings.nav-item.nav-link:hover  {
    transition: 1.5s;
    transform: rotate(180deg);
}
#ProfileTabs-tab-settings.nav-item.nav-link  {
    transition: 1.5s;
}