.FindOutMore {
    height: auto;
    background-color: #f4f4f4;
    margin: auto;
    border-radius: 16px;
}

.FindOutMore .TitleText {
    font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;

color: #6F6F6F;
}
.FindOutMore .NeoText {
    font-family: Bebas;
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 72px;
/* identical to box height */


color: #000000;
}

.FindOutMore p {
font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
/* or 200% */

font-feature-settings: 'salt' on, 'liga' off;

/* Text / Gray 900 */

color: #18191F;
}