.nav-link a{
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
text-align: center;
text-decoration: none;
vertical-align: middle;
/* black */

color: #272727;
}
.nav-link {
    margin: auto;
    padding-left: 15px !important;
    padding-right: 15px !important;
}