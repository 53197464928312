.RevealHeaderHome {
    /* background-image: url("../../public/pages/images/reveal-standard/backgrounds/spectator-background.webp"); */
    background-size:cover;
    background-position: center bottom;

    height: 90vh;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

  }

  .vcenter-item{
    display: flex;
    align-items: center;
}

.spectatorStyling {
  color: #119235;
  font-weight: 600;
}

.pitcrewStyling {
  color: #956113;
  font-weight: 600;
}

.crewchiefStyling {
  color: #1467B4;
  font-weight: 600;
}

.racedirectorStyling {
  color: #B74AC9;
  font-weight: 600;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
    }
}

#avatar-clickable{
  cursor: pointer;
}

.selected {
  opacity: 1;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.LoadingRoleBG {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  top: 0px;
  width: 100%;
  /* background-color: #000000;
opacity: 0.5; */
  
}

.not-selected {
  opacity: 0.5;
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.nft-view { 
  max-height: 500px;
  margin: auto;
}

.nft-view-m{
  max-height: 500px;
  margin: auto;
}
