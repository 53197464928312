.backgroundMD {
    background-image:url(./bike-background-desktop.png);
    background-repeat: no-repeat;
  background-size: cover;
    height: calc(100vh - 95px);
}

.backgroundMDm {
    background-image:url(./bike-background-desktop.png);
    background-repeat: no-repeat;
  background-size: cover;
}