.standard-card {
    filter: drop-shadow(0px 4px 31px rgba(0, 0, 0, 0.1));
    border-radius: 15px;
}

p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: Inter;
}

.card-title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.card-img-top {
    padding: 17px;
}

.card-price {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

}

.card-button {
    background-color: black;
    border-radius: 25px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.price-padding {
    padding-top: 64px;
}

.spacer {
    margin-top: 150px;
    margin-bottom: 150px;
}

/* h1 {
    font-family: 'Bebas Neue', cursive;
    font-size: 32px;
    line-height: 64px;
    color: #18191F;
} */

.mask-text {
    color: #de466c;
    background: url(../../public/pages/images/mint/holographic-background.jpg) 0 0 / cover no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: '50px';
    line-height: '48px';
}

.footerCopyrightText {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
}

.built-on-flow {
    font-family: 'Roboto';
    font-size: 40px;
}