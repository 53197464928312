.LoadingBG {
    background-image: url('./LoadingBg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
    top: 0px;
    width: 100%;
    /* background-color: #000000;
opacity: 0.5; */
    
}
.LoadingBG::after{
background-color: rgba(0, 0, 0, 0.5);
}
.LoadingNft {
z-index: 2001;
    background: #FFFFFF;
box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
backdrop-filter: blur(24px);
/* Note: backdrop-filter has minimal browser support */
border-radius: 16px;
max-width: 722px;
position: relative;
top: 40%; left: 50%;
  transform: translate(-50%,-50%);

}